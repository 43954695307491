// Fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// Variables
@import "variables";

// Icons
$bootstrap-icons-font-dir: "~bootstrap-icons/font/fonts";
@import "~bootstrap-icons/font/bootstrap-icons.scss";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Timeline
@import "./timeline.scss";

.owl-carousel-360 {
    .owl-prev {
        position: absolute !important;
        top: 37% !important;
        left: 0 !important;
        background-color: white !important;
        width: 30px !important;
        height: 30px !important;
        border-radius: 20px !important;
        color: black !important;
        cursor: pointer !important;
        font-size: 20px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;

        > span {
            font-size: 25px !important;
        }
    }

    .owl-next {
        position: absolute !important;
        top: 37% !important;
        right: 0 !important;
        background-color: white !important;
        width: 30px !important;
        height: 30px !important;
        border-radius: 20px !important;
        color: black !important;
        cursor: pointer !important;
        font-size: 20px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;

        > span {
            font-size: 25px !important;
        }
    }

    .custom-title {
        opacity: 0;
        transition: .5s;
    }

    .custom-title:hover {
        opacity: 1;
    }
}

.owl-carousel-brand {
    img {
        filter: grayscale(100%); 
        height: 100%;
    }

    img:hover {
        filter: grayscale(0%); 
    }
}