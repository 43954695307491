$body-bg: #FFFFFF;

$font-family-sans-serif: "Poppins", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$construct1: #00989e;
$construct2: #005357;
$construct3: #a0d4d1;

$primary: $construct1;
$secondary: $construct3;

$custom-file-text: (
    en: "Browse",
    es: "Elegir",
);

$btn-border-radius:      1rem;
$btn-border-radius-sm:   1rem;
$btn-border-radius-lg:   1rem;
